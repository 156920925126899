import { QITable } from "../../../../components";
import { useEffect, useState } from "react";
import { getLocalizedString } from "../../../../shared/translation";
import { capitalize } from "../../utils";
import { PaginationHeader, PaginationFooter } from "../../../ManagementPortal/Shared";
import moment from "moment";

const TripsListWrapper = ({
  tracksData,
  pagination,
  setActivitiesTimeRange = () => {},
  selectedId,
}) => {
  const timeConversion = (time) => {
    if (time === null || time === undefined) return "-";
    return moment(time)?.format(" DD/MM/YYYY - hh:mm:ss A");
  };

  const getLocation = (position) => {
    if (position?.address?.name) return position?.address?.name;
    if (
      position?.lat === undefined ||
      position?.lng === undefined ||
      position?.lat === null ||
      position?.lng === null
    )
      return "-";
    return position?.lat + "," + position?.lng;
  };

  const [updatedData, setUpdatedData] = useState([]);

  const headers = [
    {
      label: getLocalizedString("mode", "Mode"),
      key: "modes",
      className: "mode",
    },
    {
      label: getLocalizedString("status", "Status"),
      key: "status",
      className: "status",
    },
    {
      label: getLocalizedString("start_time", "Start Time"),
      key: "start_time",
      className: "start-time",
    },
    {
      label: getLocalizedString("start_location", "Start Location"),
      key: "start_location",
      className: "start-location",
    },
    {
      label: getLocalizedString("end_time", "End Time"),
      key: "end_time",
      className: "end-time",
    },
    {
      label: getLocalizedString("end_location", "End Location"),
      key: "end_location",
      className: "end-location",
    },
  ];

  useEffect(() => {
    setUpdatedData(
      tracksData?.map((data) => {
        return {
          ...data?.data,
          start_time: timeConversion(data?.data?.start_time),
          modes: capitalize(data?.data?.modes),
          status: capitalize(data?.data?.status),
          end_time: timeConversion(data?.data?.end_time),
          start_location: getLocation(data?.data?.start_position),
          end_location: getLocation(data?.data?.end_position),
        };
      })
    );
  }, [tracksData]);

  return (
    <>
      <QITable
        headers={headers}
        data={updatedData}
        className="activities"
        showSelected={true}
        selectedId={selectedId}
        handler={{
          rowHandler: (data) =>
            setActivitiesTimeRange({
              start_time: moment(data?.start_time, "DD/MM/YYYY - hh:mm:ss A"),
              end_time: moment(data?.end_time, "DD/MM/YYYY - hh:mm:ss A"),
            }),
        }}
      />
      <aside className="qi-pagination-wrapper">
        <PaginationHeader pagination={pagination} />
        {pagination && <PaginationFooter pagination={pagination} />}
      </aside>
    </>
  );
};

export default TripsListWrapper;
